import React from 'react';
import Link from 'gatsby-link';
import dogImg from './dog.png';

import './Error404Template.scss';

const Error404Template = () => {
  return (
    <div className="mars-error-404 mars-container mars-padding-y--xxl mars-text--center">
      <img
        className="mars-max-width--md"
        src={dogImg}
        alt="Dog sheltering from rain in a kennel"
      />
      <h1 className="mars-alpha">404</h1>
      <p className="mars-delta">Oh wow!</p>
      <p className="mars-body">
        It looks like the dog's buried that page! Could you try again?
      </p>
      <Link to="/" className="mars-button">
        Return home
      </Link>
    </div>
  );
};

export default Error404Template;
