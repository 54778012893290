import React from 'react';
import { graphql } from 'gatsby';
import Error404Template from '@components/templates/Error404Template/Error404Template';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <GTMPageLoad
        {...{ page_type: `404`, page_taxonomy_field: `404`, page_name: `404` }}
      />
      <Error404Template />
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
